/* About.css */
.about-container {
    padding: 2rem;
    background-color: #f5f5f5; /* Light gray background */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .profile-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #333; /* Dark gray text */
  }
  
  .info-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .info-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    width: 300px;
    text-align: center;
  }
  
  .info-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .info-card h2 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .info-card p {
    font-size: 1rem;
    color: #666; /* Lighter gray text */
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .info-cards {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  