.hero {
    text-align: center;
    padding: 4rem 2rem;
    background-color: #f5f5f5; /* Light gray background */
  }
  
  .hero h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  
  .hero-button {
    padding: 0.75rem 3rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
  }
  
  .hero-button:hover {
    background-color: #555; /* Lighter black on hover */
  }

  
  