.contact-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem;
    background-color: #f5f5f5; /* Light gray background */
    flex-wrap: wrap;
  }
  
  /* Contact Form */
  .contact-form {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    width: 400px;
  }
  
  .contact-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #333;
  }
  
  .form-group {
    margin-right: 1rem;
    margin-left: 1rem;
    width: 400px;
  }

  .form-group2 {
    margin-right: 16px;
    margin-left: 16px;
    width: 370px;
  }
  .form-group3 {
    margin-right: 16px;
    margin-left: 16px;
    width: 370px;
  }
  
  .form-row {
    margin-left: 1rem;
    margin-right: 1rem    ;
  }
   
  label {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  input,
  textarea {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #f5f5f5;
    box-sizing: border-box;

  }
  
  input::placeholder,
  textarea::placeholder {
    color: #aaa;
  }
  
  textarea {
    resize: none;
  }
  
  .form-submit {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
  }
  
  .form-submit:hover {
    background-color: #555;
  }
  
  /* Contact Info */
  .contact-info {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    width: 400px;
  }
  
  .contact-info h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #333;
  }
  
  .contact-detail {
    font-size: 1.2rem;
    color: #333;
    margin-right: 0.5rem;
  }
  
  .contact-info p {
    font-size: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-form,
    .contact-info {
      width: 100%;
      max-width: 500px;
    }
  }