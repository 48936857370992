.blogs-container {
    padding: 2rem;
    background-color: #f5f5f5; /* Light gray background */
    text-align: center;
  }
  
  .blogs-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #000; /* Black text */
  }
  
  /* Blogs List */
  .blogs-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  /* Blog Card */
  .blog-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; /* Fixed width */
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  
  .blog-image-placeholder {
    height: 150px;
    background-color: #ddd;
  }
  
  .blog-content {
    padding: 1rem;
  }
  
  .blog-meta {
    font-size: 0.9rem;
    color: #666; /* Gray text */
    margin-bottom: 0.5rem;
  }
  
  .blog-category {
    background-color: #aaf0d1; /* Soft teal */
    color: #000;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    margin-left: 0.5rem;
  }
  
  .blog-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #000; /* Black text */
  }
  
  .blog-description {
    font-size: 0.9rem;
    color: #666; /* Gray text */
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  
  .blog-button {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.9rem;
    text-align: center;
    width: fit-content;
    align-self: flex-start;
  }
  
  .blog-button:hover {
    background-color: #444; /* Darker black */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blogs-list {
      flex-direction: column;
      align-items: center;
    }
  
    .blog-card {
      width: 100%;
      max-width: 500px;
    }
  }
  